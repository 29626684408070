/* You can add global styles to this file, and also import other style files */
// @import "~@ng-select/ng-select/themes/material.theme.css";

@use '@ng-matero/extensions' as mtx;
@use '@angular/material' as mat;

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  )
));

@include mtx.all-component-themes($theme);
@include mtx.all-experimental-component-themes($theme);


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field,
.mat-mdc-form-field {
  width: 100%;
  min-width: 300px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
// @include mat.typography-hierarchy($theme);

mat-card-title,
mat-card-content,
mat-card-title.mat-mdc-card-title,
mat-card-content.mat-mdc-card-content {
//  display: flex;
  justify-content: center;
}

.mat-mdc-card-title {
  padding: 16px 16px 0;
}

.mat-mdc-no-data-row td {
  padding-left: 24px;
  padding-right: 24px;
}

.error {
  padding: 16px;
  width: 300px;
  color: white;
  background-color: red;
}

.button {
  display: flex;
  justify-content: flex-end;
}

tbody tr td.mat-cell {
  padding-right: 7px;
}
